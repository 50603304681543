<template>
  <div>
    <v-app>
      <v-container>
        <router-view />
      </v-container>
    </v-app>
  </div>
</template>

<script>
  export default {
    name: 'Index',
  }
</script>

<style scoped>

</style>
